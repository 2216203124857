import $ from 'jquery'

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});

$(function() {
  $('#quickchatbutton').click(function() {
    $('#quickchatbutton').toggleClass("opened closed");
    $('#quickchat').toggleClass("opened closed");
    console.log("opened");
  })
  $('#quickchatclose').click(function() {
    $('#quickchatbutton').toggleClass("opened closed");
    $('#quickchat').toggleClass("opened closed");
    console.log("closed");
  })
 });


var isFirefox = (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
var isMobile = (typeof window.orientation !== "undefined") ||
               (navigator.userAgent.indexOf('IEMobile') !== -1);

if(isFirefox && !isMobile) {
    $('a[href^="tel:"]').click(function() { return false; });
}


  $("#menu-toggle a").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $("#menu-toggle").toggleClass("toggled");
  });


  return Munki

})($)

export default Munki
